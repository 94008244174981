import React from "react";
import Components from "./components.js";
import styled from "styled-components";
import { Box } from "@rebass/grid";
import StyledBox from "./box";
import { H1 } from "./page-elements";

const Wrapper = styled(StyledBox)`
  color: ${({ theme }) => theme.colors.grey[600]};
`;

const StyledTitle = styled(H1)`
  color: ${({ theme }) => theme.colors.grey[900]};
`;
const DetailPage = props => (
  <Wrapper mt={32} width={[1]}>
    <StyledTitle>{props.blok.project_title}</StyledTitle>

    <Box>
      {props.blok.body &&
        props.blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok
          })
        )}
    </Box>
  </Wrapper>
);

export default DetailPage;
