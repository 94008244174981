import styled from "styled-components";

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  font-family: ${({ theme }) => theme.font.sans};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-family: ${({ theme }) => theme.font.sans};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize["xl"]};
  font-family: ${({ theme }) => theme.font.sans};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.grey[700]};
`;

export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize["lg"]};
  font-family: ${({ theme }) => theme.font.sans};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.grey[700]};
`;
