import React from "react";
import { H2, H3, H4 } from "./page-elements";

const Headline = props => {
  const { text, size } = props.blok;

  if (size === "H3") {
    return <H3>{text}</H3>;
  } else if (size === "H2") {
    return <H2>{text}</H2>;
  } else if (size === "H4") {
    return <H4>{text}</H4>;
  } else {
    return <H2>{text}</H2>;
  }
};

export default Headline;
