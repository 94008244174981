import styled from "styled-components";
import { color, space, layout, position } from "styled-system";
import { Box } from "@rebass/grid";

const StyledBox = styled(Box)`
${color}
${space}
${layout}
${position}
justify-content: center;
`;

StyledBox.displayName = "StyledBox";

export default StyledBox;
