//components
import Grid from "./grid";
import Teaser from "./teaser";
import Feature from "./feature";
import NavItem from "./nav_item";
import Headline from "./headline";
import GalleryLong from "./galleryLong";
import GalleryGrid from "./galleryGrid";
import Image from "./image";
import Markdown from "./markdown";

//page
import Page from "./page";
import DetailPage from "./detailPage";

import ComponentNotFound from "./component_not_found";

const ComponentList = {
  page: Page,
  detail_page: DetailPage,
  grid: Grid,
  teaser: Teaser,
  feature: Feature,
  nav_item: NavItem,
  headline: Headline,
  gallery_grid: GalleryGrid,
  gallery_long: GalleryLong,
  image: Image,
  markdown: Markdown
};

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
