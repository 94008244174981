import React, { useState } from "react";
import styled from "styled-components";
import StyledBox from "./box";
import { space } from "styled-system";

const Link = styled.a`
  cursor: pointer;
  pointer-events: auto;
`;

const Category = styled.a.attrs({
  mr: [15, 20]
})`
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.grey[600]}!important;
  text-transform: uppercase;
  margin-right: 20px;
  margin-bottom: 40px;
  border-radius: 8px;

  :hover {
    color: ${({ theme }) => theme.colors.grey[800]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    text-decoration: underline !important;
  }
  ${space}
`;

const ImageContainer = styled.img`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 8px;
`;

const Overlay = styled.div`
  transition: 0.3s ease;
  background-color: ${({ theme }) => theme.colors.grey[800]};
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 8px;
  :hover {
    opacity: 0.9;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  pointer-events: auto;
`;

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Title = styled.p`
  color: white;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 0.8rem;
  text-align: center;
  display: inline;
  border: 1px solid white;
  margin-right: 10px;
  padding: 7px;
`;

const GalleryLong = props => {
  const { items, categoryList } = props.blok;

  console.log(items);

  const [state, setState] = useState({
    list: items,
    filterKey: "latest"
  });

  const { list, filterKey } = state;

  const FILTER_DEFS = {
    branding: item => item.branding,
    graphic: item => item.graphic,
    illustration: item => item.illustration,
    latest: item => item,
    ux: item => item.ux
  };
  const ImageWrapper = styled.div``;

  const filteredList = list.filter(FILTER_DEFS[filterKey]);

  return (
    <div>
      {categoryList ? (
        <StyledBox mb={[24, 48]}>
          {categoryList.map(categoryListItem => (
            <Category
              key={categoryListItem}
              onClick={() =>
                setState({
                  ...state,
                  filterKey: `${categoryListItem}`
                })
              }
            >
              {categoryListItem}
            </Category>
          ))}
        </StyledBox>
      ) : null}
      {filteredList.map(item => (
        <ImageWrapper key={item._uid}>
          <Link href={item.url.cached_url}>
            <Wrapper>
              <Overlay>
                <Text>
                  <Title> {item.title} </Title>
                  {Array.isArray(item.tags) &&
                    item.tags.map(tag => <Subtitle>{tag}</Subtitle>)}
                </Text>
              </Overlay>

              <ImageContainer alt="image" src={item.image} />
            </Wrapper>
          </Link>
        </ImageWrapper>
      ))}
    </div>
  );
};

export default GalleryLong;
