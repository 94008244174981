import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import StyledBox from "./box";
import { space, layout } from "styled-system";

const Thumbnail = styled(StyledBox).attrs({
  width: ["300px", "450px", "200px"],
  height: ["300px", "450px", "200px"]
})`
  position: relative;

  overflow: hidden;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.04);
  }
  ${layout}
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const Category = styled.a.attrs({
  mr: [15, 20]
})`
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.grey[600]};
  margin-bottom: 40px;

  :hover {
    color: ${({ theme }) => theme.colors.grey[800]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  ${space}
`;

const Title = styled.p`
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Link = styled.a`
  cursor: pointer;
`;

const GalleryGrid = props => {
  const { items, categoryList } = props.blok;

  console.log(items);

  const [state, setState] = useState({
    list: items,
    filterKey: "latest"
  });

  const { list, filterKey } = state;

  const FILTER_DEFS = {
    branding: item => item.branding,
    graphic: item => item.graphic,
    illustration: item => item.illustration,
    latest: item => item,
    ux: item => item.ux
  };

  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  return (
    <div>
      {categoryList ? (
        <StyledBox mb={[24, 48]}>
          {categoryList.map(categoryListItem => (
            <Category
              key={categoryListItem}
              onClick={() =>
                setState({
                  ...state,
                  filterKey: `${categoryListItem}`
                })
              }
            >
              {categoryListItem}
            </Category>
          ))}
        </StyledBox>
      ) : null}

      <Flex flexWrap="wrap" flexDirection={["column", "column", "row"]}>
        {filteredList.map(item => (
          <Flex justifyContent="center" key={item._uid}>
            <Link href={item.url.cached_url}>
              <Thumbnail mr={[0, 16]} mb={[24, 16]}>
                <Image src={item.image} />
                <Title>{item.title}</Title>
              </Thumbnail>
            </Link>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default GalleryGrid;
