import styled from "styled-components";

export const P = styled.p`
  font-size: ${({ theme }) => theme.fontSize["base"]};
  font-family: ${({ theme }) => theme.font.sans};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  strong {
    font-weight: bold;
  }
`;
