import React, { useState, useEffect } from "react";
import Components from "./components.js";
import styled, { css } from "styled-components";
import { H4 } from "./page-elements";
import hamburgerIcon from "../images/burger.svg";
import closeIcon from "../images/close.svg";
import { Flex, Box } from "@rebass/grid";
import { layout } from "styled-system";

//social media
import instagram from "../images/instagram.svg";
import dribbble from "../images/dribbble.svg";
import behance from "../images/behance.svg";
import linkedin from "../images/linkedin.svg";

import StyledBox from "./box";

const navigationStyle = css`
li {
  display: block;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.grey[600]};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    font-size: ${({ theme }) => theme.fontSize.sm};
    text-transform: uppercase;
    :hover {
      color: ${({ theme }) => theme.colors.grey[900]};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }
`;

const Link = styled.a``;

//////////////mobile/////////////////
const MobileNav = styled(Flex)`
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
  height: 60px;
  overflow-x: hidden;
`;
const MobileNavigationList = styled.ul`
  position: absolute;
  background-color: white;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  height: 100vh;
  transition: height 2s;
  z-index: 1;
  overflow: hidden;
  padding: 16px;
  ${navigationStyle},
  li {
    margin-bottom: 10px;
  }
`;

const Test = styled.img`
  transition: opacity 1s;
`;
const Hamburger = styled(StyledBox).attrs({
  display: ["block", "block", "none"]
})``;

const BottomSocialWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: 24px;
  border-top: 1px solid grey;
  width: 80%;
`;
const SocialMedia = styled.div`
  margin-bottom: 10px;
  a img {
    max-height: 24px;
  }
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
`;

//////////////desktop/////////////////
const DesktopNav = styled(StyledBox).attrs({
  p: [24, 32],
  display: ["none", "none", "block"]
})``;

const Image = styled.img.attrs({
  height: ["100px", "150px"],
  width: ["100px", "150px"]
})`
  object-fit: cover;
  border-radius: 50%;
  ${layout}
`;
const ImageContent = styled(StyledBox).attrs({
  pt: [16, 64],
  pb: [32, 64]
})`
  text-align: center;
`;

const NavigationList = styled.ul`
  padding: 0;
  margin: 0;
  ${navigationStyle}
`;

const Note = styled.p`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const NavigationContainer = styled(Box)`
  position: ${({ open }) => (open ? "fixed" : "inherit")};
  background-color: ${({ open }) => (open ? "white" : "transparent")};
  width: 100%;
  z-index: 2;
`;

const Navi = props => {
  const { nav_items, logo_image } = props.blok;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth > 832 && open) {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handler);
  }, [open]);

  const socialMediaArray = [
    {
      name: "instagram",
      url: "https://www.instagram.com/asiajacko/",
      icon: instagram
    },
    {
      name: "linkedin",
      url:
        "https://www.linkedin.com/in/asia-jackowska-39b827117/?originalSubdomain=nl",
      icon: linkedin
    }
  ];

  return (
    <NavigationContainer open={open}>
      <MobileNav onClick={() => setOpen(!open)}>
        <Hamburger>
          <Test src={open ? closeIcon : hamburgerIcon} alt="menu" />
        </Hamburger>
      </MobileNav>
      <ImageContent>
        <a href="/">
          <Image src={logo_image} />
        </a>
      </ImageContent>
      {open ? (
        <MobileNavigationList>
          {nav_items &&
            nav_items.map(blok =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok
              })
            )}
          <Flex justifyContent="center">
            <BottomSocialWrapper>
              <SocialMedia>
                {socialMediaArray.map(socialMedia => (
                  <Link href={socialMedia.url}>
                    <img alt={socialMedia.name} src={socialMedia.icon} />
                  </Link>
                ))}
              </SocialMedia>
              <Note>©2023 asia jackowska</Note>
            </BottomSocialWrapper>
          </Flex>
        </MobileNavigationList>
      ) : null}

      <DesktopNav>
        <StyledBox mt={[24, 36]}>
          <H4 as="h1">ASIA JACKOWSKA</H4>
        </StyledBox>
        <StyledBox mt={[24, 48]}>
          <NavigationList>
            {nav_items &&
              nav_items.map(blok =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok
                })
              )}
          </NavigationList>
        </StyledBox>
        <StyledBox mt={[24, 48]}>
          <Note>©2023 asia jackowska</Note>
        </StyledBox>
      </DesktopNav>
    </NavigationContainer>
  );
};

export default Navi;
