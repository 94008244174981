import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div``;

const ImageContainer = styled.img`
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
`;

const Image = props => {
  const { image } = props.blok;
  return (
    <ImageWrapper>
      <ImageContainer alt="image" src={image} />
    </ImageWrapper>
  );
};

export default Image;
