import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const StyledMarkdown = styled(ReactMarkdown)`
  margin-bottom: 20px;
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
  p {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.grey[600]};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSize["2xl"]};
    font-family: ${({ theme }) => theme.font.sans};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.colors.grey[800]};
    line-height: 1.5;
  }
  h3 {
    font-size: ${({ theme }) => theme.fontSize["xl"]};
    font-family: ${({ theme }) => theme.font.sans};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.colors.grey[800]};
    line-height: 1.5;
  }
  h4 {
    font-size: ${({ theme }) => theme.fontSize["lg"]};
    font-family: ${({ theme }) => theme.font.sans};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.colors.grey[800]};
    line-height: 1.5;
  }
`;

const Markdown = props => {
  const text = props.blok.markdown;
  return <StyledMarkdown>{text}</StyledMarkdown>;
};

export default Markdown;
