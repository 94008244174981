import React from "react";
import Link from "gatsby-link";
import SbEditable from "storyblok-react";

const NavItem = props => (
  <SbEditable content={props.blok}>
    <li>
      <Link
        to={
          "/" +
          (props.blok.link.cached_url === "home"
            ? ""
            : props.blok.link.cached_url)
        }
      >
        {props.blok.name}
      </Link>
    </li>
  </SbEditable>
);

export default NavItem;
