import React from "react";
import Components from "./components.js";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import StyledBox from "./box";

const Wrapper = styled(StyledBox)``;

const Page = props => {
  return (
    <Wrapper>
      <Flex justifyContent="center" flexDirection="column">
        {props.blok.body &&
          props.blok.body.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok
            })
          )}
      </Flex>
    </Wrapper>
  );
};

export default Page;
