import React from "react";
import { ThemeProvider } from "styled-components";
import Navi from "../components/navi";
import Components from "../components/components.js";
import SbEditable from "storyblok-react";
import styled from "styled-components";
import { layout, color, position, space } from "styled-system";
import { Flex } from "@rebass/grid";
import StyledBox from "./box";
import { GlobalStyle, theme } from "../theme/global-style";

const MainWrapper = styled.div.attrs({
  maxWidth: ["100%", "60rem", "65rem"]
})`
  position: relative;
  margin: 0 auto;

  ${layout}
  ${color}
`;

const NaviWrapper = styled.div.attrs({
  width: ["100%", "100%", 0.2],
  position: ["relative", "relative", "sticky"]
})`
  top: 1px;
  height: 100%;
  ${color}
  ${space}
  ${layout}
  ${position}
`;
const Content = styled(StyledBox)`
  min-height: 100vh;
  background-color: white;
`;

const Layout = props => {
  const { content, globalNavi, dateCreated } = props;

  return (
    <SbEditable content={content}>
      <ThemeProvider theme={theme}>
        <MainWrapper backgroundColor={["customGrey"]}>
          <Flex
            justifyContent="center"
            flexDirection={["column", "column", "row"]}
          >
            <NaviWrapper>
              <Navi blok={globalNavi}></Navi>
            </NaviWrapper>
            <Content width={[1, 1, 0.8]} p={[16, 32, 64]}>
              {React.createElement(Components(content.component), {
                key: content._uid,
                blok: content,
                dateCreated: dateCreated
              })}
            </Content>
          </Flex>
        </MainWrapper>
        <GlobalStyle />
      </ThemeProvider>
    </SbEditable>
  );
};

export default Layout;
